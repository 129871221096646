@use "../styles/Mixins.scss" as mixin;
// Div alignment with flex property
.center {
  @include mixin.flex-align(center, center);
}
.center-start {
  @include mixin.flex-align(center, flex-start);
}
.center-end {
  @include mixin.flex-align(center, flex-end);
}
.top-center {
  @include mixin.flex-align(baseline, center);
}
.top-start {
  @include mixin.flex-align(baseline, flex-start);
}
.top-end {
  @include mixin.flex-align(baseline, flex-end);
}
.bottom-center {
  @include mixin.flex-align(end, center);
}
.bottom-start {
  @include mixin.flex-align(end, flex-start);
}
.bottom-end {
  @include mixin.flex-align(end, flex-end);
}
.flex {
  @include mixin.flex;
}
.flex-gap {
  @include mixin.flex;
  gap: 20px;
}
.space-between {
  @include mixin.flex-space-between;
}
//   text alignment
.right {
  text-align: right;
}
.middle {
  text-align: center;
}
