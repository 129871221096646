.theme-sa {
  .login-module-brand-icon {
    background-image: url("../../assets/images/sip-abacus-logo.png");
  }
  .bg,
  .bg:hover {
    background-color: theme.$primary_clr;
  }
  .bg-lite,
  .bg-lite:hover {
    background-color: theme.$secondary_clr !important;
  }
  .color {
    color: theme.$primary_clr !important;
  }
  .color-sec {
    color: theme.$secondary_clr !important;
  }
  .dateRangepickerBig input {
    color: theme.$secondary_clr !important;
  }
  .MuiDayCalendar-header span {
    color: theme.$secondary_clr !important;
  }
  .MuiDayCalendar-weekContainer .Mui-selected,
  .MuiPickersYear-root .Mui-selected,
  .MuiPickersMonth-root .Mui-selected {
    background-color: theme.$secondary_clr !important;
  }
  .theme-border {
    border: 1px solid theme.$secondary_clr !important;
  }
  .bg-message-box {
    background-color: theme.$lite-yellow;
  }
  .bg-message-icon-box {
    background-color: theme.$message-box-icon-bg;
  }
  .react-calendar__tile--active {
    background: theme.$secondary_clr;
  }
  .react-calendar__tile--now {
    background: theme.$secondary_clr;
  }
  .iconColor {
    color: theme.$secondary_clr;
  }
}
.theme-ga {
  .login-module-brand-icon {
    background-image: url("../../assets/images/ga/ga-logo.png");
  }
  .bg,
  .bg:hover {
    background-color: theme.$primary-clr-ga;
  }
  .bg-lite,
  .bg-lite:hover {
    background-color: theme.$primary-clr-ga;
  }
  .color {
    color: theme.$primary-clr-ga;
  }
  .color-sec {
    color: theme.$primary-clr-ga !important;
  }
  .dateRangepickerBig input {
    color: theme.$primary-clr-ga !important;
  }
  .MuiDayCalendar-header span {
    color: theme.$primary-clr-ga !important;
  }
  .MuiDayCalendar-weekContainer .Mui-selected,
  .MuiPickersYear-root .Mui-selected,
  .MuiPickersMonth-root .Mui-selected {
    background-color: theme.$primary-clr-ga !important;
  }
  .bg-message-box {
    background-color: theme.$primary-lite-ga;
  }
  .bg-message-icon-box {
    background-color: theme.$message-box-icon-bg-ga;
  }
  .react-calendar__tile--active {
    background: theme.$primary-clr-ga;
  }
  .react-calendar__tile--now {
    background: theme.$primary-clr-ga;
  }
  .iconColor {
    color: theme.$primary-clr-ga;
  }
  border {
    border: 1px solid theme.$primary-clr-ga !important;
  }
}
