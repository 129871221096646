//Global art theme
@use '../styles/ThemeSipAbacus.scss' as theme;
@use '../styles/Mixins.scss' as mixin;
.theme-ga {
  .login-module-brand-icon {
    background-image: url("../../assets/images/ga/ga-logo.png");
  }
  .theme-backgroung-color,
  .theme-backgroung-color:hover {
    background-color: theme.$primary-clr-ga;
  }
  .theme-backgroung-primary-lite-color {
    background-color: theme.$primary-lite-ga;
  }
  .color-sec {
    color: theme.$primary-clr-ga !important;
  }
  .dateRangepickerBig input {
    color: theme.$primary-clr-ga !important;
  }
  .MuiDayCalendar-header span {
    color: theme.$primary-clr-ga !important;
  }
  .MuiDayCalendar-weekContainer .Mui-selected,
  .MuiPickersYear-root .Mui-selected,
  .MuiPickersMonth-root .Mui-selected {
    background-color: theme.$primary-clr-ga !important;
  }
  .alert-message-icon-box-bg-color {
    background-color: theme.$message-box-icon-bg-ga;
  }
  .react-calendar__tile--active {
    background: theme.$primary-clr-ga;
  }
  .react-calendar__tile--now {
    background: theme.$primary-clr-ga;
  }
  .iconColor {
    color: theme.$primary-clr-ga;
  }

  .login-module-background {
    background-image: url("../../assets/images/ga/bg.svg");
  }

  .corner-mask {
    background-image: url("../../assets/images/ga/mask-corner.svg");
    background-position: bottom right;
  }
  .centercode-control-icon {
    background-image: url("../../assets/images/ga/account.svg");
  }
  .username-control-icon {
    background-image: url("../../assets/images/ga/fullscreen.svg");
  }
  .password-control-icon {
    background-image: url("../../assets/images/ga/locked.svg");
  }
  .logo-change-password > span {
    @include mixin.image-no-repeat-size-cover;
    background-image: url("../../assets/images/ga/forgot-password.svg");
    width: 120px;
    height: 88px;
  }
  .email-control-icon {
    background-image: url("../../assets/images/ga/email.svg");
  }
  .mobile-control-icon {
    background-image: url("../../assets/images/ga/mobile.svg");
  }
  .Mui-checked {
    color: theme.$primary-clr-ga !important;
  }
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: theme.$primary-clr-ga !important;
  }
  .theme-bg {
    background-color: theme.$primary-clr-ga;
  }
  .theme-lite-bg {
    background-color: theme.$primary-lite-ga;
  }
  .login-btn:hover {
    background-color: theme.$primary-clr-ga;
  }
}
