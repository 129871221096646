@font-face {
  font-family: "poppins";
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: "poppins-label";
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}

$primary_clr: #c15900;
$primary-clr-ga: #0095da;
$secondary_clr: #f48220;
$primary-lite-sa: #f7dcc4;
$primary-lite-ga: #99ddfd;
$theme-bg-clr: #f2f6fc;
$input-bg-clr: #fcfcfb;
$action-edit: #68c1f8;
$action-delete: #f27373;
$action-group: #40bc79;
$action-account-tree: #ffb222;
$action-invoice: #1464b4;
$action-download: #9f0909;
$link: #4199ff;
$white: #fff !default;
$gray: #808080 !default;
$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #ff0000 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$dark-yellow: #c9b200;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$warn-orange: #ea3d13 !default;
//attendance color
$lite-red: #e35252;
$lite-yellow: #ffcf76;
$vilot: #9165f5;
// end
$black: #000;
$darkGray: #313131;
$border-blue: #72beff;
$t-blue: #ebf5ff;
$border-gray: #e5e5e5;
$label-gray: #9d9d9d;
$r-green: #85efd1;
$g-yellow: #d0d000;
$g-yellow-dark: #b7b712;
$background-blue: #e5f1ff;
$message-box-icon-bg: #e5a838;
$message-box-icon-bg-ga: #45bbf3;
$app-font-default: "poppins";
$label: "poppins-label";
$toaster-success-border: #06ac47;
$toaster-success-bg: #e6fff0;
$toaster-error-bg: #f7b2b2;
$transparent-black: #00000012;
