@use '../../assets//styles/ThemeSipAbacus.scss' as theme;
@use "../../assets/styles/Utils.scss" as utils;
@use "../../assets/styles/Mixins.scss" as mixin;

.MuiDivider-root {
  display: none;
}

.MuiList-root {
  li {
    > div {
      padding-left: 18px;
    }
    div {
      color: theme.$white;

      span {
        font-size: 14px;
      }

      div {
        div {
          div {
            div {
              border-left: 1px solid theme.$white;
              margin-left: 10px;
              padding-left: 12px;
              padding-bottom: 0px;
              padding-top: 3px;
              span {
                font-size: 12px;
              }

              div {
                border: none;
              }
            }
          }
        }
      }
    }
  }
}
.menu-icon {
  svg {
    font-size: 20px;
  }
}

.side-nav-img {
  height: 44px;
  margin-left: -12px;
  background-color: theme.$white;
  border-radius: 6px;
}

.mainMenuColor div {
  color: #000 !important;
  span {
    font-size: 14px;
  }
}

.app-tittle {
  font-size: 18px !important;
  color: theme.$darkGray;
  font-weight: 550;
}

.MuiBadge-badge {
  height: 14px !important;
  font-size: 10px !important;
}

// change password
.changePassword-smallbox {
  background: #ffffff;
  box-shadow: 0px 1px 2px #00000014;
  border-radius: 10px;
  margin: 20px 0 !important;
}

// view user
.viewUser-btn {
  margin-left: 100px !important;
}

.viewUser-btn2 {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #b9b9b9 !important;
  border-radius: 13px !important;
  color: #313131 !important;
  text-transform: capitalize !important;
}

.clear-btn2 {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #b9b9b9 !important;
  border-radius: 20px !important;
  color: #313131 !important;
  text-transform: capitalize !important;
}

.instructor-btn {
  background: #b9b9b9 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.createUser-btn {
  background: #313131;
  padding: 3px 15px;
  border-radius: 20px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.jnKa-Dc {
  color: #4d4f5c !important;
}

.grid-border {
  border-right: 1px solid #e6e6e6;
  padding: 20px;
}

// feedback
.course-box {
  background: #ebf5ff 0% 0% no-repeat;
  border: 1px solid #50a1fe;
  border-radius: 10px;
  padding: 15px !important;
  margin-top: 20px;
}

.datepicker .DateInput_input {
  font-size: 15px !important;
  padding: 7px 17px 7px !important;
}

.datepicker .DateRangePickerInput {
  background-color: transparent !important;
}

.datepicker .DateInput {
  background-color: transparent !important;
}

.fileBox {
  background: #ebf5ff 0% 0% no-repeat padding-box;
  border: 2px dashed #4199ff;
  border-radius: 10px;
  padding: 20px 200px;
  margin-top: 15px;
}

.uploadImgBox {
  padding: 20px;
  width: 611px;
  background: #ebf5ff 0% 0% no-repeat padding-box;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

.addmore-Btn {
  border: none;
  background: transparent;
  text-decoration: underline;
  display: flex;
  justify-content: flex-end;
}

// students
.students-date-box {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 15px 15px 15px 30px;
}

.students-date-box .form-label {
  color: #f5821f;
  font-weight: bold;
  font-size: 18px;
}

.search-btn {
  background: #f5821f !important;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 20px;
  color: #ffff !important;
  text-transform: capitalize !important;
}
.border-btn {
  border: 1px solid #f5821f !important;

  border-radius: 20px;
  color: #f5821f !important;
  text-transform: capitalize !important;
}

.opensec-btn {
  background: #ffffff;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 20px;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize !important;
}

.toggle-btn {
  border-radius: 20px;
  height: 30px;
  margin-top: 9px;
  button {
    text-transform: capitalize;
  }
  .Mui-selected {
    text-transform: capitalize;
    color: #ffffff !important;
    background-color: rgb(25 118 210) !important;
  }
}

.app-tittle-div {
  margin-left: 21px !important;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.css-hyum1k-MuiToolbar-root {
  padding-left: 10px !important;
}

.app-title-mid {
  width: 100%;
  background-color: #fff;
}

.right-drawer {
  .side-bar-title {
    font-size: 18px;
    font-weight: 600;
  }
  > div {
    main {
      padding: 0 !important;
    }
  }

  .header {
    justify-content: space-between;
    min-height: 40px;
    border-bottom: 1px solid theme.$border-gray;
    padding: 0px;
  }

  .right-drawer-default {
    .MuiDrawer-paperAnchorRight {
      @include mixin.right-drawer-padding;
    }
  }

  .right-drawer-large {
    .MuiDrawer-paperAnchorRight {
      width: 60%;
      @include mixin.right-drawer-padding;
    }

    @media (max-width: 480px) {
      .MuiDrawer-paperAnchorRight {
        width: 100%;
      }
    }
  }
}
.css-1f6u4kq-MuiGrid-root > .MuiGrid-item {
  padding-top: 0px !important;
}
.drawer {
  > div {
    z-index: 1;
  }
}
