@use '../styles/ThemeSipAbacus.scss' as theme;
@use '../styles/Mixins.scss' as mixin;
.theme-sa {
  .login-module-brand-icon {
    background-image: url("../../assets/images/sip-abacus-logo.png");
  }
  .theme-backgroung-color,
  .theme-backgroung-color:hover {
    background-color: theme.$secondary_clr !important;
  }
  .theme-backgroung-primary-lite-color {
    background-color: theme.$lite-yellow;
  }
  .color-sec {
    color: theme.$secondary_clr !important;
  }
  .dateRangepickerBig input {
    color: theme.$secondary_clr !important;
  }
  .MuiDayCalendar-header span {
    color: theme.$secondary_clr !important;
  }
  .MuiDayCalendar-weekContainer .Mui-selected,
  .MuiPickersYear-root .Mui-selected,
  .MuiPickersMonth-root .Mui-selected {
    background-color: theme.$secondary_clr !important;
  }
  .theme-border {
    border: 1px solid theme.$secondary_clr !important;
  }
  .alert-message-icon-box-bg-color {
    background-color: theme.$lite-yellow;
  }
  .alert-message-icon-box-bg-color {
    background-color: theme.$message-box-icon-bg;
  }
  .react-calendar__tile--active {
    background: theme.$secondary_clr;
  }
  .react-calendar__tile--now {
    background: theme.$secondary_clr;
  }
  .iconColor {
    color: theme.$secondary_clr;
  }
  .login-module-background {
    background-image: url("../../assets/images/bg.svg");
  }

  .corner-mask {
    background-image: url("../../assets/images/mask-corner.svg");
    background-position: bottom right;
  }
  .centercode-control-icon {
    background-image: url("../../assets/images/account.svg");
  }

  .username-control-icon {
    background-image: url("../../assets/images/fullscreen.svg");
  }

  .password-control-icon {
    background-image: url("../../assets/images/locked.svg");
  }
  .logo-change-password > span {
    @include mixin.image-no-repeat-size-cover;
    background-image: url("../../assets/images/forgot-password.svg");
    width: 120px;
    height: 88px;
  }
  .email-control-icon {
    background-image: url("../../assets/images/email.svg");
  }
  .mobile-control-icon {
    background-image: url("../../assets/images/mobile.svg");
  }
  .Mui-checked {
    color: theme.$secondary_clr !important;
  }
  .login-btn:hover {
    background-color: theme.$secondary_clr;
  }

  .theme-sec-bg {
    background-color: theme.$secondary_clr;
  }

  .theme-bg {
    background-color: theme.$primary_clr;
  }
  .theme-lite-bg {
    background-color: theme.$primary-lite-sa;
  }
}
