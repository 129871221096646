@import url('https://fonts.cdnfonts.com/css/poppins');
body {
  @font-face {
    font-family: 'Poppins';
    src: url("./assets/fonts/Poppins/Poppins-Regular.ttf");
    }
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
  

code {
  font-family: 'Poppins',
    monospace;
}
.css-ahj2mt-MuiTypography-root{
  @font-face {
    font-family: 'Poppins';
    src: url("./assets/fonts/Poppins/Poppins-Regular.ttf");
    }
}
