.border {
  @include mixin.border(all, 1, theme.$border-gray);
}
.border-top {
  @include mixin.border(up, 1, theme.$border-gray);
}
.border-right {
  @include mixin.border(right, 1, theme.$border-gray);
}
.border-left {
  @include mixin.border(left, 1, theme.$border-gray);
}
.border-down {
  @include mixin.border(down, 1, theme.$border-gray);
}
.border-left-right {
  @include mixin.border(lf, 1, theme.$border-gray);
}
.border-left-right-bottom {
  @include mixin.border(lfb, 1, theme.$border-gray);
}
.border-top-bottom {
  @include mixin.border(tb, 1, theme.$border-gray);
}
.blue-border {
  @include mixin.border-card(solid, theme.$border-blue, theme.$background-blue);
}
.gray-border {
  @include mixin.border-card(solid, theme.$border-gray, theme.$white);
}
.blue-border-dashed {
  @include mixin.border-card(
    dashed,
    theme.$border-blue,
    theme.$background-blue
  );
}
// Border radius
.radius-10 {
  border-radius: 10px;
}
.radius-4 {
  border-radius: 4px;
}
