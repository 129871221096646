.sub-module-header {
  font-size: 1.3rem !important;
  font-weight: 600 !important;
  color: theme.$darkGray;
  font-family: theme.$app-font-default;
}
.small-f {
  font-size: 0.9rem;
}
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.bolder {
  font-weight: bolder;
}
