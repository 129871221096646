.franchisees-box {
  background: #c9ddaf;
  padding: 24px 35px;
}
.fee-text {
  color: #f4b6b5 !important ;
}
.fee-box {
  border-left: 1px solid #80808040;
  // padding: 10px 65px;
}
.active-text {
  color: #6fb8b8 !important ;
}
.cake1 {
  width: 15px;
  margin-right: 10px;
  color: #7cd3ff;
}
.cake2 {
  width: 15px;
  margin-right: 10px;
  color: #e5b78e;
}
.cake3 {
  width: 15px;
  margin-right: 10px;
  color: #a0a0ff;
}
.cake4 {
  width: 15px;
  margin-right: 10px;
  color: #fbe9b5;
}
.cake5 {
  width: 15px;
  margin-right: 10px;
  color: #6fb8b8;
}
.download-btn {
  color: #4199ff;
  font-size: large;
}
.linechart
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 7px 14px;
}
.download-icon {
  color: #4199ff !important;
}
.filter-icon {
  font-size: 30px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 8px;
  height: 40px;
  width: 40px;
  display: flex;
  position: relative;
}
.filter-box {
  border: 1px solid #e6e6e6;
  padding: 10px 20px;
  position: absolute;
  height: 500px;
  width: 300px;
  right: 50px;
  top: 100px;
  background: #fff;
  border-radius: 6px;
}
.birthday-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 24px;
  padding: 4px;
  z-index: 999999999;
  overflow-y: auto;
  height: 650px;
}
