@use '/src/assets/styles/ThemeSipAbacus.scss' as theme;
@use '/src/assets/styles/Mixins.scss' as mixins;

@import "./portal/Dashboard/dashboard.scss";
@import "./portal/dashboardHo//dashboardHo.scss";
@import "/src/assets/styles/Utils.scss";

body {
  background-repeat: no-repeat, repeat;
  background-size: cover;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
