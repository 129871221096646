.mainBox {
  background: #ffffff;
  box-shadow: 0px 4px 20px #c4c8d03d;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  // padding: 10px;
  margin: 10px;
  height: 100%;
}
.mainBox-small {
  background: #ffffff;
  box-shadow: 0px 4px 20px #c4c8d03d;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin: 10px;
}
.mainBox2 {
  background: #ffffff;
  box-shadow: 0px 4px 20px #c4c8d03d;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin: 10px 0;
}
.lastBox {
  background: #ffffff;
  box-shadow: 0px 4px 20px #c4c8d03d;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin: 10px;
}
.last2Box {
  background: #ffffff;
  box-shadow: 0px 4px 20px #c4c8d03d;
  height: 78px;
  width: 99%;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin: 10px;
}
.dashboard-heading .form-label {
  font-weight: 600 !important;
  font-size: 17px !important;
  letter-spacing: 0px;
  color: #1b2767;
}
.annousment-box {
  background: #f7dcc4;
  padding: 20px;
  border-radius: 10px 0px 0px 10px;
}
.confirm-title {
  color: #000;
  font-size: 20px;
}
.align-item-center {
  align-items: center;
}
.viewall {
  font-size: 16px !important;
}
.text-dark {
  color: #000;
}
.certificate-box1 {
  background: #a9d1c6;
  padding: 10px;
  border-radius: 10px;
}
.certificate-box2 {
  padding: 10px;
  background: #c9eefd;
  border-radius: 10px;
}
.certificate-box3 {
  padding: 10px;
  background: #f4b6b5;
  border-radius: 10px;
}
.certificate-icon {
  padding: 10px;
  background: #4d4f5c;
  border-radius: 5px;
  display: inline-flex;
  color: #a9d1c6;
  margin-bottom: 20px;
}
.certificate-icon2 {
  color: #c9eefd;
}
.certificate-icon3 {
  color: #f4b6b5;
}
.whitebox {
  background: #ffffff;
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;
  text-align: center;
  color: #000;
}
.certificate-title .form-label {
  font-size: 18px !important;
}
.feesBox {
  background: #f4b6b5;
  padding: 10px;
  border-radius: 0px 10px 10px 0px;
  opacity: 1;
}
.active-title {
  color: #1b2767 !important;
  font-weight: 700;
  font-size: 28px;
}
.active-box {
  padding: 10px 40px;
}
.active-box .form-label {
  font-weight: 400 !important;
  color: grey;
  font-size: 18px !important;
}
.active-small-title p {
  color: #393939;
  padding: 10px 0;
  font-size: 19px;
  font-weight: 800;
}
.fees-icon {
  background: #edf1f7;
  padding: 15px;
  border-radius: 50%;
  color: #f4b6b5;
  font-size: 5px;
}
.sms-icon {
  background: #4d4f5c;
  border-radius: 5px;
  width: 44px;
  height: 44px;
  line-height: 55px;
  text-align: center;
  color: #c9ddaf;
}
.balance-box {
  background: #c9ddaf;
  border-radius: 0px 10px 10px 0px;
  padding: 44px 20px;
}
.admition-box {
  background-color: #ff8f8f;
  border-bottom-left-radius: 10px;
  text-align: center;
}
.sms .MuiGrid-container {
  margin-bottom: 0px !important;
}
.doughnut-chart {
  height: 300px;
  width: 240px;
}
.bar-chart {
  height: 230px;
  width: 490px;
}
.marquee {
  --gap: 80px;
  position: relative;
  overflow: hidden;
  gap: var(--gap);
}

.marquee__content {
  overflow: auto;
  display: flex;
  justify-content: space-between;
  gap: var(--gap);
  animation: scroll 10s linear infinite;
}
.marquee__content p {
  color: #000;
}
@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-50% - var(--gap)));
  }
}
.dropdown-month .form-label {
  color: #919191;
  font-weight: 400 !important;
}
.barchart {
  border-radius: 20px !important;
}
.doughnut-percentage {
  height: 80px;
  font-size: 50px;
  color: #000;
  font-weight: 700;
}
.doughnut-wrap {
  width: 100%;
  height: 80px;
  position: absolute;
  left: 0;
  line-height: 10px;
  text-align: center;
}
.position-relative {
  position: relative;
}
.lead .right-drawer {
  display: none;
}
.telegram-box {
  background-color: #000;
  color: #fff;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 4px;
}
.birthday-modal ::-webkit-scrollbar {
  display: none;
}
.css-13mtvpn-MuiModal-root-MuiDrawer-root .MuiDrawer-paper {
  width: 900px !important;
}
.dashboard-stepper {
  > div:nth-child(3) {
    width: 60%;
  }
}
.active-student-ho {
  padding-top: 11px;
}
.dashboard-active-students-title {
  width: 40%;
}
