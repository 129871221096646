.full {
  width: 100%;
}
.half {
  width: 50%;
}
.quarter {
  width: 25%;
}
.triquarter {
  width: 75%;
}
.width-35 {
  width: 35%;
}
.width-80 {
  width: 80%;
}
.width-90 {
  width: 90%;
}
//padding style
.padding-10 {
  padding: 10px;
}
.no-padding {
  padding: 0px;
}
.padding-5 {
  padding: 5px;
}
.padding-x-10 {
  padding: 0 10px;
}
.padding-y-10 {
  padding: 10px 0;
}
.padding-y-20 {
  padding: 20px 0;
}
.padding-up-5 {
  padding-top: 5px;
}
.padding-20 {
  padding: 20px;
}
.padding-right-20 {
  padding-right: 20px;
}
.padding-down-15 {
  padding-bottom: 15px;
}
.padding-down-5 {
  padding-bottom: 5px;
}
.padding-right-5 {
  padding-right: 5px;
}
.padding-left-5 {
  padding-left: 5px;
}
.padding-left-10 {
  padding-left: 10px;
}
.padding-up-15 {
  padding-top: 15px;
}
.padding-up-25 {
  padding-top: 25px;
}

//margin style
.margin-right-5 {
  margin-right: 5px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-right-20 {
  margin-right: 20px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-left-5 {
  margin-left: 5px;
}
.margin-left-45 {
  margin-left: 45px;
}
.margin-up-15 {
  margin-top: 15px;
}
.margin-up-150 {
  margin-top: 150px;
}
.margin-up-8 {
  margin-top: 8px;
}
.margin-up-10 {
  margin-top: 10px;
}
.margin-up-20 {
  margin-top: 20px;
}
.margin-up-40 {
  margin-top: 40px;
}
.margin-down-5 {
  margin-bottom: 5px;
}
.margin-down-10 {
  margin-bottom: 10px;
}
.margin-down-20 {
  margin-bottom: 20px;
}
.margin-x-10 {
  margin: 0 10px;
}
.margin-y-10 {
  margin: 10px 0;
}
.margin-x-20 {
  margin: 0 20px;
}
.margin-left-45 {
  margin-left: 45px;
}
