@use "sass:math";
@mixin image-no-repeat-size-cover {
    background-repeat: no-repeat, repeat;
    background-size: cover;
    display: inline-block;
}
@mixin image-no-repeat {
    background-repeat: no-repeat, repeat;
    display: inline-block;
}
@mixin flex{
    display: flex;
}

@mixin flex-space-between{
    @include flex;
    justify-content: space-between;
}

@mixin flex-align($alignV,$alignH){
    @include flex;
    align-items: $alignV;
    justify-content: $alignH;
}

@mixin bold{
    font-weight: bold !important;
}
@mixin hand-cursor{
    cursor: pointer;
}
@mixin underline{
    text-decoration: underline !important;
}
@mixin link($color){
    @if $color{
        color: $color;
    }
    @include hand-cursor;
    @include underline;
}
@mixin border-card($border, $border-clr, $bg-clr){
    border: 2px $border $border-clr;
    background-color: $bg-clr;
    border-radius: 10px;
}
@mixin right-drawer-padding(){
    padding: 5px 15px 5px 15px;
}
@mixin border($direction, $size, $color){
    border-color: transparent;
    border-width:  math.div($size, 1)+px;
    border-style: solid;
    @if $direction == up {
        border-top-color: $color;
    }
    @else if $direction == right{
        border-right-color: $color;
    }
    @else if $direction == down{
        border-bottom-color: $color;
    }
    @else if $direction == left{
        border-left-color: $color;
    }
    @else if $direction == all{
        border-color: $color;
    }
    @else if $direction == lf{
        border-left-color: $color;
        border-right-color: $color;
    }
	@else if $direction == lfb{
        border-left-color: $color;
        border-right-color: $color;
		border-bottom-color: $color;
    }
	@else if $direction == all{
        border-left-color: $color;
        border-right-color: $color;
		border-bottom-color: $color;
		border-top-color: $color;
    }
    @else if $direction == tb{
        border-top-color: $color;
        border-bottom-color: $color;
    }
}
@mixin toster-border {
  border-left: 8px solid;
  border-radius: 0;
  padding: 10px;
  min-width: 350px;
}
